<template>
  <!-- this.getLevelId || !this.getSubjectId -->
  <div class="testBasket">
    <div class="text-btn">
      <!-- <i class="el-icon-delete"></i> -->
      <img
        style="width: 20px"
        src="@/assets/imgs/resource/questionL.svg"
        alt=""
      />
      试题篮
      <div class="number">{{ exampaperplan.countAll }}</div>
      <div class="quertion-list-box">
        <div class="quertion-list">
          <div class="name">已选试题</div>
          <div>题量</div>
          <div>分数</div>
          <div class="del" @click="exampaperplanquestionDel()">清空</div>
        </div>
        <div class="edit-scroll-style question-cnt">
          <div
            v-for="(v, i) in exampaperplan.examPaperPlanQuestion"
            :key="i"
            class="quertion-list question-item"
          >
            <div class="name">{{ v.typeName }}</div>
            <div>{{ v.count }}</div>
            <div>{{ v.score | setScore }}</div>
            <div>
              <i
                class="el-icon-delete"
                @click="exampaperplanquestionDel(v)"
              ></i>
            </div>
          </div>
        </div>

        <div class="quertion-list question-item">
          <div class="name">合计</div>
          <div>{{ exampaperplan.countAll }}</div>
          <div>{{ exampaperplan.scoreAll }}</div>
          <div></div>
        </div>
        <div class="btn-box">
          <el-button type="primary" class="btn-all" @click="showPaperList"
            >试卷预览</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  exampaperplan,
  exampaperplanquestionDel,
} from "@/core/api/resource/knowledgePointsPaper";
import { mapGetters } from "vuex";
import { getStore } from "@/core/util/store";
export default {
  name: "TestBasket",
  filters: {
    setScore(val) {
      return val.toFixed(1);
    },
  },
  props: {
    planId: {
      type: [Number, null],
      default: null,
    },
    planType: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      exampaperplan: {},
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  created() {
    //初始化下数据，如果vuex中不存在level和 subject  （基本就是不在资源模块下）
    this.setParameter();
    // 获取试题篮
    this.getQuestion();
  },
  methods: {
    getData() {
      this.setParameter();
      this.getQuestion();
    },
    setParameter() {
      if (!this.getLevelId || !this.getSubjectId) {
        let level = getStore({ name: "levelId" });
        let subject = getStore({ name: "subjectId" });
        this.$store.commit("setLevelId", level.value);
        this.$store.commit("setSubjectId", subject.value);
      }
    },
    showPaperList() {
      if (this.exampaperplan.examPaperPlanQuestion.length == 0) {
        this.$message({
          message: "请先选择试题",
          type: "warning",
        });
        return;
      }
      this.$router.push({
        name: "PaperPreview",
        query: {
          id: this.exampaperplan.examPaperPlan.id,
        },
      });
    },
    exampaperplanquestionDel(v) {
      let data = {
        examPaperPlanId: this.exampaperplan.examPaperPlan.id,
        // type:this.exampaperplan.id,
      };
      // console.log(v);
      if (v && v.type) {
        data.type = v.type;
      }
      this.$confirm("确认删除试题篮试题吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          exampaperplanquestionDel(data).then(() => {
            // console.log(res);
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.getQuestion();
            // this.exampaperplan.examPaperPlanQuestion = this.exampaperplan.examPaperPlanQuestion.filter(
            //   item => item.type != v.type
            // );

            // 调用父组件方法 ，更新列表
            this.$emit("getNewList");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消“清空”操作",
          });
        });
    },
    getQuestion() {
      if (!this.getLevelId || !this.getSubjectId) {
        return;
      }
      let data = {
        id: this.planId || "",
        levelId: this.getLevelId,
        subjectId: this.getSubjectId,
        schoolId: getStore({ name: "userInfo" }).schoolId,
        type: this.planType || 0,
        status: 0,
      };
      exampaperplan(data).then((res) => {
        this.exampaperplan = res.data.data;
        this.exampaperplan.scoreAll = 0;
        this.exampaperplan.countAll = 0;
        res.data.data.examPaperPlanQuestion.forEach((index) => {
          this.exampaperplan.scoreAll =
            this.exampaperplan.scoreAll + index.score;
          this.exampaperplan.countAll =
            this.exampaperplan.countAll + parseInt(index.count);
        });

        this.exampaperplan.scoreAll = this.exampaperplan.scoreAll.toFixed(1);
        if (!this.planId) {
          this.$emit("setPlanid", this.exampaperplan.examPaperPlan.id);
        }
        // console.log(this.exampaperplan.questionIdList);
        this.$emit("setQuestionList", this.exampaperplan.questionIdList);
        this.$emit(
          "setQuestionKnowledge",
          this.exampaperplan.questionKnowledge
        );
      });
    },
  },
};
</script>
<style scoped lang="scss">
.testBasket {
  position: fixed;
  top: 150px;
  right: 20px;
  z-index: 100;
  .text-btn {
    width: 50px;
    padding: 15px;
    background: $primary-color;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 3px;
    text-align: center;
    line-height: 20px;
    position: relative;
    z-index: 40;
    &:hover {
      .quertion-list-box {
        display: block;
      }
    }
    .quertion-list-box {
      display: none;
      position: absolute;
      right: 50px;
      top: 0;
      padding: 10px;
      border: 1px solid $primary-color;
      background-color: white;
      .btn-box {
        padding: 8px 0;
        .btn-all {
          width: 196px;
          height: 40px;
        }
      }
      .quertion-list {
        display: flex;
        // padding: 0 20px;

        div {
          line-height: 38px;
          width: 56px;
          text-align: center;
          color: $primary-color;
        }
        .del {
          color: #ff7a3f;
        }
        .name {
          width: 112px;
        }
      }
      .question-item {
        div {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    i {
      font-size: 20px;
    }
    .number {
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      display: block;
      color: #fff;
      background: #ff7a3f;
      border-radius: 50%;
      font-size: 12px;
      margin-top: 10px;
    }
  }
  .question-cnt {
    max-height: 300px;
  }
  // z-index: 98;
}
</style>
