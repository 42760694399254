import request from "@/core/services/axios";

export function upload(obj) {
  return request({
    url: "/resource/exampaperrelation/my/upload",
    method: "post",
    data: obj,
  });
}

export function questionlist(query) {
  return request({
    url: "/resource/exampaperplanquestion/get/questionlist",
    method: "get",
    params: query,
  });
}

//组卷信息
export function examPaperPlan(planId) {
  return request({
    url: "/resource/exampaperplan/" + planId,
    method: "get",
  });
}
//
export function changePriority(obj) {
  return request({
    url: "/resource/exampaperplanquestion/change/priority",
    method: "post",
    data: obj,
  });
}
export function changeQuestionStatus(obj) {
  return request({
    url: "/resource/exampaperplanquestion/change/status",
    method: "post",
    data: obj,
  });
}
//保存试卷
export function planPaperSave(query) {
  return request({
    url: "/resource/exampaperplan/save",
    method: "get",
    params: query,
  });
}
//分值
export function changeQuestionScore(list) {
  return request({
    url: "/resource/exampaperplanquestion/change/score",
    method: "post",
    data: list,
  });
}
//修改题型对应的标题和描述
export function changeQuestionBaseInfo(obj) {
  return request({
    url: "/resource/exampaperplanquestion/change/questionTypeContent",
    method: "post",
    data: obj,
  });
}
//预览组卷-下载组卷
export function downloadExamPaperPlan(query) {
  return request({
    url: "/resource/exampaperplan/downloadexampaperplan",
    method: "get",
    params: query,
    timeout: 300 * 1000,
  });
}
//预览组卷-试卷分析
export function getExamPaperPlanAnalysis(examPaperPlanId) {
  return request({
    url: "/resource/exampaperplan/exampaperplananalysis/" + examPaperPlanId,
    method: "get",
  });
}
