import { render, staticRenderFns } from "./ViewPaper.vue?vue&type=template&id=add37e08&scoped=true&"
import script from "./ViewPaper.vue?vue&type=script&lang=js&"
export * from "./ViewPaper.vue?vue&type=script&lang=js&"
import style0 from "./ViewPaper.vue?vue&type=style&index=0&id=add37e08&prod&lang=scss&"
import style1 from "./ViewPaper.vue?vue&type=style&index=1&id=add37e08&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "add37e08",
  null
  
)

export default component.exports