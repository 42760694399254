<template>
  <div class="mod-config">
    <el-dialog
      :title="'试卷分析（总分：' + totalScore + '分）'"
      width="900px"
      :visible.sync="visible"
      :lock-scroll="false"
      :close-on-click-modal="false"
    >
      <div class="analysis">
        <div class="page-content">
          <div class="summary">
            <div class="overall-analysis">
              <div class="title">总体分布分析</div>
              <div class="form">
                <el-table :data="totalAnalysis" border>
                  <el-table-column
                    prop="name"
                    label="题型"
                    width="100"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div class="type-name">{{ scope.row.name }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="amount"
                    label="题量"
                    width="100"
                    align="center"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.amount + "题" }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="score"
                    label="分值"
                    width="100"
                    align="center"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.score + "分" }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="percentage"
                    label="分值占比"
                    width="100"
                    align="center"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.percentage | formatPer }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="difficulty-analysis">
              <div class="title">难度分析</div>
              <div id="diffiChart"></div>
            </div>
          </div>
          <div class="point-analysis">
            <div class="title">知识点分析</div>
            <el-table :data="knowlAnalysis">
              <el-table-column
                type="index"
                label="序号"
                width="100"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="name"
                label="知识点"
                width="380"
                align="center"
              >
                <template slot-scope="scope">
                  <div class="point">{{ scope.row.name }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="score"
                label="分值"
                width="120"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.score + "分" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="percentage"
                label="分值占比"
                width="120"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.percentage | formatPer }}
                </template>
              </el-table-column>
              <el-table-column
                prop="order"
                label="对应题号"
                width="140"
                align="center"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="confirmButton" @click="downloadCSV">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getExamPaperAnalysis } from "@/core/api/resource/exampaper";
import { getExamPaperPlanAnalysis } from "@/core/api/resource/paperList";

export default {
  filters: {
    formatPer(value) {
      value = value * 100;
      value = value.toFixed(1);
      return value + "%";
    },
  },
  data() {
    return {
      visible: false,
      name: "",
      totalScore: 0,
      totalAnalysis: [],
      diffiAnalysis: [],
      knowlAnalysis: [],
      diffiChart: null, // 难度分析图对象
    };
  },
  methods: {
    init(examPaperId) {
      getExamPaperAnalysis(examPaperId).then((response) => {
        this.processResponse(response);
      });
    },
    initPlan(examPaperPlanId) {
      getExamPaperPlanAnalysis(examPaperPlanId).then((response) => {
        this.processResponse(response);
      });
    },
    processResponse(response) {
      this.name = response.data.data.name;
      this.totalScore = response.data.data.totalScore;
      this.totalAnalysis = [];
      let totalMap = response.data.data.totalMap;
      for (let queType in totalMap) {
        this.totalAnalysis.push(
          Object.assign({ name: queType }, totalMap[queType])
        );
      }
      this.diffiAnalysis = [];
      let diffiMap = response.data.data.diffiMap;
      for (let diffiType in diffiMap) {
        this.diffiAnalysis.push({
          name: diffiType,
          value: diffiMap[diffiType],
        });
      }
      this.calDiffiPer();
      this.knowlAnalysis = [];
      let knowlMap = response.data.data.knowlMap;
      for (let knowlPoint in knowlMap) {
        this.knowlAnalysis.push(
          Object.assign({ name: knowlPoint }, knowlMap[knowlPoint])
        );
      }
      this.visible = true;
      this.$nextTick(() => {
        this.drawDiffiChart();
      });
    },
    // 计算难度百分占比
    calDiffiPer() {
      for (let i = 0; i < this.diffiAnalysis.length; i++) {
        let diffiObj = this.diffiAnalysis[i];
        let percentage = (diffiObj.value / this.totalScore) * 100;
        percentage = percentage.toFixed(1);
        diffiObj = Object.assign(diffiObj, { percentage: percentage });
      }
    },
    drawDiffiChart() {
      if (!this.diffiChart) {
        this.diffiChart = this.$echarts.init(
          document.getElementById("diffiChart"),
          null,
          { devicePixelRatio: 2.5 }
        );
      }
      // 绘制图表
      this.diffiChart.setOption({
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} <br/>占比：{d}%",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: this.diffiAnalysis,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
    downloadCSV() {
      // 直接网页端导出 csv 文件
      const rows = [];
      // 导出名称
      let nameRow = [this.name];
      rows.push(nameRow);
      // 导出总分
      let totalScoreRow = ["总分：" + this.totalScore + "分"];
      rows.push(totalScoreRow);
      // 导出总体分布分析
      let totalAnalysisRow1 = ["总体分布分析"];
      let totalAnalysisRow2 = ["题型", "题量", "分值", "分值占比"];
      rows.push([]);
      rows.push(totalAnalysisRow1);
      rows.push(totalAnalysisRow2);
      for (let i = 0; i < this.totalAnalysis.length; i++) {
        let totalAnalysisObj = this.totalAnalysis[i];
        let totalAnalysisObjRow = [];
        totalAnalysisObjRow.push(totalAnalysisObj.name);
        totalAnalysisObjRow.push(totalAnalysisObj.amount);
        totalAnalysisObjRow.push(totalAnalysisObj.score);
        totalAnalysisObjRow.push(
          this.toPercentage(totalAnalysisObj.percentage)
        );
        rows.push(totalAnalysisObjRow);
      }
      // 导出难度分析
      let diffiAnalysisRow1 = ["难度分析"];
      let diffiAnalysisRow2 = ["难度", "占比"];
      rows.push([]);
      rows.push(diffiAnalysisRow1);
      rows.push(diffiAnalysisRow2);
      for (let i = 0; i < this.diffiAnalysis.length; i++) {
        let diffiAnalysisObj = this.diffiAnalysis[i];
        let diffiAnalysisObjRow = [];
        diffiAnalysisObjRow.push(diffiAnalysisObj.name);
        diffiAnalysisObjRow.push(diffiAnalysisObj.percentage + "%");
        rows.push(diffiAnalysisObjRow);
      }
      // 导出知识点分析
      let knowlAnalysisRow1 = ["知识点分析"];
      let knowlAnalysisRow2 = [
        "序号",
        "知识点",
        "分值",
        "分值占比",
        "对应题号",
      ];
      rows.push([]);
      rows.push(knowlAnalysisRow1);
      rows.push(knowlAnalysisRow2);
      for (let i = 0; i < this.knowlAnalysis.length; i++) {
        let knowlAnalysisObj = this.knowlAnalysis[i];
        let knowlAnalysisObjRow = [];
        knowlAnalysisObjRow.push(i + 1);
        knowlAnalysisObjRow.push(knowlAnalysisObj.name);
        knowlAnalysisObjRow.push(knowlAnalysisObj.score);
        knowlAnalysisObjRow.push(
          this.toPercentage(knowlAnalysisObj.percentage)
        );
        knowlAnalysisObjRow.push(knowlAnalysisObj.order.replaceAll(", ", "，"));
        rows.push(knowlAnalysisObjRow);
      }
      // 导出 scv 文件
      let csvContent = "data:text/csv;charset=utf-8,\uFEFF"; // 使用 UTF-8 with BOM 编码，Excel 显示中文才不会乱码
      rows.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
      });
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.name + " 试卷分析.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    toPercentage(float) {
      let str = "";
      if (float) {
        str = Number(float * 100).toFixed(1);
        str += "%";
      }
      return str;
    },
  },
};
</script>

<style lang="scss">
.analysis {
  .cancelButton {
    background-color: #424242;
    color: #ffffff;
  }
  .confirmButton {
    background-color: #2474ed;
    color: #ffffff;
  }
  .page-content {
    .summary {
      .el-table td,
      .el-table th {
        height: 59px;
      }
    }
    .point-analysis {
      .el-table {
        border-top: 1px solid #ebeef5;
      }
      .el-table th > .cell {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
      .el-table .cell {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .el-table--border::after,
      .el-table--group::after,
      .el-table::before {
        background-color: transparent;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.analysis {
  .page-content {
    .title {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #2474ed;
      margin-bottom: 19px;
    }
    .summary {
      display: flex;
      margin-bottom: 20px;
      .overall-analysis {
        margin-right: 28px;
        .type-name {
          color: #2474ed;
        }
      }
      .difficulty-analysis {
        #diffiChart {
          width: 400px;
          height: 400px;
        }
      }
    }
    .point-analysis {
      padding-bottom: 40px;
      .point {
        color: #2474ed;
      }
    }
  }
}
</style>
