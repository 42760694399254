<template>
  <div class="view-paper question-box-img-style">
    <div class="paper-title">
      <div class="go-back-cnt" @click="$router.go(-1)">
        <div>
          <i class="el-icon-arrow-left"></i>
          返回上级
        </div>
      </div>
    </div>
    <div v-loading="fullscreenLoading" class="paper-info">
      <div class="info">
        <div class="name">{{ examPaper.name || "" }}</div>
        <div class="grade-type">
          <span>浏览量： {{ examPaper.countView || 0 }} </span>
          <span>收藏：{{ examPaper.countFav || 0 }}</span>
        </div>
      </div>
      <div class="paper-tips-box">
        <div v-if="getshowTips(examPaper, '1')">PDF</div>
        <div v-if="getshowTips(examPaper, '2')">图片</div>
        <div v-if="getshowTips(examPaper, '3')" class="word-tips">Word</div>
        <div v-if="examPaper.statusStep >= 3" class="cut-tips">已裁题</div>
        <div v-if="examPaper.statusStep < 3">未裁题</div>
      </div>
      <div class="label-cnt">
        <div class="text">
          <!-- province  resGradeId  type countQues  countView  countFav -->
          <div style="display: flex">
            <!-- 地区 -->
            <div class="normal">
              {{ getAddress(examPaper.province, examPaper.city) }}
            </div>
            <!-- 年级 -->
            <div v-if="!dataIsEmpty(examPaper.resGradeId)" class="normal">
              {{ getLabelByValue(examPaper.resGradeId, gradeOptions) }}
            </div>
            <!-- 学校 -->
            <div class="normal">
              {{ examPaper.resSchoolName || examPaper.schoolName }}
            </div>
            <!-- 学校级别 -->
            <!-- <div v-if="!dataIsEmpty(examPaper.schoolLevel)" class="normal">
              {{ getLabel(examPaper.schoolLevel, schoolLevelOptions) }}
            </div> -->
          </div>
          <div class="text-btm">
            <!-- 题量 -->
            <div v-if="examPaper.statusStep >= 3" class="normal">
              {{ "共" + examPaper.countQues }}题
            </div>

            <div v-if="!dataIsEmpty(examPaper.collectionTime)" class="normal">
              收录于：{{ examPaper.collectionTime }}
            </div>
          </div>
        </div>
        <div class="btns">
          <el-button
            plain
            :class="{ 'el-button-on': examPaper.relationId > 0 }"
            @click="checkFav(examPaper)"
          >
            <img src="@/assets/resourse/paper/collect.png" alt="" />
            <img
              class="on"
              src="@/assets/resourse/paper/collect_on.png"
              alt=""
            />
            <template v-if="examPaper.relationId > 0"> 已收藏</template>
            <template v-else> 收藏</template>
          </el-button>
          <el-button plain @click="downloadExamPaper(examPaper)">
            <img src="@/assets/resourse/paper/down.png" alt="" />
            <img class="on" src="@/assets/resourse/paper/down_on.png" alt="" />
            原卷下载
          </el-button>
          <el-button
            v-if="examPaper.statusStep >= 3"
            plain
            class="fx-btn"
            @click="paperAnalysis(examPaper.id)"
          >
            <img src="@/assets/resourse/paper/analyse.png" alt="" />
            <img
              class="on"
              src="@/assets/resourse/paper/analyse_on.png"
              alt=""
            />
            试卷分析
          </el-button>
        </div>
      </div>
    </div>
    <!-- <div
            v-if="examPaper.statusStep >= 3 || examPaper.cutStatus == 1"
            class="btn"
            type="text"
            @click="dlOptSettingsDialogVisible = true"
          >
            同类题下载
          </div>

          <div
            v-if="examPaper.statusStep >= 3 || examPaper.cutStatus == 1"
            class="btn"
            type="text"
            @click="linkToIndex"
          >
            沿用细目表
          </div> -->
    <div class="paper-cnt">
      <div v-if="examPaperQuestions.length == 0">
        <template v-if="examPaperType == 0">
          <div
            v-if="examPaper.resultPath && examPaper.resultPath.length > 0"
            class="paper-preview"
            v-html="examPaper.resultPath"
          ></div>
        </template>
        <template v-else>
          <div v-for="(item, key) in examPaperImgs" :key="key">
            <el-image
              style="
                width: 100%;
                height: 100%;
                border: solid 1px #e0e0e0;
                border-radius: 4px;
              "
              :src="item"
              :preview-src-list="examPaperImgs"
            >
            </el-image>
          </div>
        </template>
      </div>
      <div v-else>
        <div
          v-if="currentSubjectExamQuestions.length > 0"
          class="paper-content-main"
        >
          <div class="paper-content-main-header">
            <div>
              <el-button plain @click="clickDisplayAllOriQuestion">
                <template v-if="displayAllOriQuestion === 0">
                  显示全部题
                </template>
                <template v-else-if="displayAllOriQuestion === 1">
                  仅显示同类题
                </template>
              </el-button>
              <el-button plain @click="clickDisplayAllQuestionVar">
                <template v-if="displayAllQuestionVar === 0">
                  显示全部同类题
                </template>
                <template v-else-if="displayAllQuestionVar === 1">
                  隐藏全部同类题
                </template>
              </el-button>
            </div>
            <div>
              <el-button
                v-if="examPaper.statusStep >= 3"
                type="text"
                @click="dlOptSettingsDialogVisible = true"
                >同类题下载</el-button
              >
              <el-button
                v-if="examPaper.statusStep >= 3"
                type="text"
                @click="linkToIndex"
              >
                沿用细目表
              </el-button>
            </div>
          </div>
          <template v-for="(question, index) in currentSubjectExamQuestions">
            <div
              v-if="question.display === 1"
              :key="index"
              class="paper-question"
            >
              <div :key="btnKey" class="paper-question-header">
                <div class="paper-question-header-msg">
                  <div
                    class="paper-question-num"
                    :title="question.examPaperNum"
                  >
                    {{ question.examPaperNum }}
                  </div>
                  <div class="paper-question-type">{{ question.typeName }}</div>
                  <div class="paper-question-difficulty">
                    {{ displayDifficulty(question.difficulty) }}
                  </div>
                  <div
                    v-if="
                      question.gradeScoringRate != null &&
                      question.gradeScoringRate != undefined
                    "
                    class="paper-question-gradescoringrate"
                  >
                    {{
                      "得分率 " +
                      displayGradeScoringRate(question.gradeScoringRate)
                    }}
                  </div>
                </div>
                <el-button
                  v-if="planQuestionList.indexOf(question.id) == -1"
                  type="text"
                  icon="el-icon-circle-plus-outline"
                  @click="exampaperplanquestion(question)"
                >
                  加入试题篮
                </el-button>
                <el-button
                  v-else
                  type="text"
                  icon="el-icon-remove-outline"
                  @click="exampaperplanquestionDel(question)"
                >
                  移除试题篮
                </el-button>
              </div>
              <div
                class="paper-question-content"
                :class="{ isCollapse: question.isCollapse === 1 }"
                @click="clickExamQuestionCollapseIcon(question, true)"
              >
                <div v-html="question.question"></div>
              </div>
              <div class="paper-question-footer">
                <div class="paper-question-knowledge">
                  <div
                    v-if="question.knowledgeArray.length > 0"
                    class="paper-question-knowledge-title"
                  >
                    知识点：
                  </div>
                  <template
                    v-for="(knowledge, kIndex) in question.knowledgeArray"
                  >
                    <div
                      :key="kIndex"
                      class="paper-question-knowledge-name"
                      :title="knowledge"
                    >
                      {{ knowledge }}
                    </div>
                  </template>
                </div>
                <div class="paper-question-action-bar">
                  <div
                    class="paper-question-collapse-icon"
                    @click="clickExamQuestionCollapseIcon(question)"
                  >
                    <template v-if="question.isCollapse === 1">
                      <i class="el-icon-caret-bottom" title="展开"></i>
                    </template>
                    <template v-else-if="question.isCollapse === 0">
                      <i class="el-icon-caret-top" title="收起"></i>
                    </template>
                  </div>
                  <div
                    class="paper-question-action"
                    @click="clickDisplayQuestionVar(question)"
                  >
                    <template v-if="question.displayOpt === 1">
                      隐藏同类题
                    </template>
                    <template v-else-if="question.displayOpt === 0">
                      显示同类题
                    </template>
                  </div>
                  <div class="paper-question-action-sepaline">|</div>
                  <div
                    class="paper-question-action"
                    @click="clickAnswerAnalysisButton(question)"
                  >
                    答案解析
                  </div>
                </div>
              </div>
              <div
                v-if="question.isCollapseAnswer === 0"
                class="paper-question-below"
              >
                <div v-if="question.newQuestion" class="below-item">
                  <p :key="btnKey" class="below-item-title">
                    <span> 当前试题没有答案解析，已为你推荐同类题 </span>
                    <el-button
                      v-if="planQuestionList.indexOf(question.newId) == -1"
                      type="text"
                      icon="el-icon-circle-plus-outline"
                      @click="exampaperplanquestion(question, 1)"
                    >
                      加入试题篮
                    </el-button>
                    <el-button
                      v-else
                      type="text"
                      icon="el-icon-remove-outline"
                      @click="exampaperplanquestionDel(question, 1)"
                    >
                      移除试题篮
                    </el-button>
                  </p>
                  【同类题】
                  <div v-html="question.newQuestion"></div>
                </div>
                <div
                  v-if="question.answer && question.answer.length > 0"
                  class="below-item"
                >
                  【答案】
                  <div v-html="question.answer"></div>
                </div>
                <div
                  v-if="question.analysis && question.analysis.length > 0"
                  class="below-item"
                >
                  【解答】
                  <div v-html="question.analysis"></div>
                </div>
                <div
                  v-if="question.analyse && question.analyse.length > 0"
                  class="below-item"
                >
                  【分析】
                  <div v-html="question.analyse"></div>
                </div>
                <div
                  v-if="question.ability && question.ability.length > 0"
                  class="below-item"
                >
                  【点评】
                  <div v-html="question.ability"></div>
                </div>
              </div>
            </div>
            <template
              v-for="(questionOpt, optIndex) in question.examQuestionOpts"
            >
              <div
                v-if="questionOpt.display === 1"
                :key="index + '-' + optIndex"
                class="paper-question"
              >
                <div :key="btnKey" class="paper-question-header">
                  <div class="paper-question-header-msg">
                    <div class="paper-question-var-num">同类题</div>
                    <div class="paper-question-type">
                      {{ questionOpt.typeName }}
                    </div>
                    <div class="paper-question-difficulty">
                      {{ displayDifficulty(questionOpt.difficulty) }}
                    </div>
                  </div>
                  <el-button
                    v-if="planQuestionList.indexOf(questionOpt.id) == -1"
                    type="text"
                    icon="el-icon-circle-plus-outline"
                    @click="exampaperplanquestion(questionOpt)"
                  >
                    加入试题篮
                  </el-button>
                  <el-button
                    v-else
                    type="text"
                    icon="el-icon-remove-outline"
                    @click="exampaperplanquestionDel(questionOpt)"
                  >
                    移除试题篮
                  </el-button>
                </div>
                <div
                  class="paper-question-content"
                  :class="{ isCollapse: questionOpt.isCollapse === 1 }"
                  @click="clickExamQuestionCollapseIcon(questionOpt, true)"
                >
                  <div v-html="questionOpt.question"></div>
                </div>
                <div class="paper-question-footer">
                  <div class="paper-question-knowledge">
                    <div
                      v-if="questionOpt.knowledgeArray.length > 0"
                      class="paper-question-knowledge-title"
                    >
                      知识点：
                    </div>
                    <template
                      v-for="(knowledge, kIndex) in questionOpt.knowledgeArray"
                    >
                      <div
                        :key="kIndex"
                        class="paper-question-knowledge-name"
                        :title="knowledge"
                      >
                        {{ knowledge }}
                      </div>
                    </template>
                  </div>
                  <div class="paper-question-action-bar">
                    <div
                      class="paper-question-collapse-icon"
                      @click="clickExamQuestionCollapseIcon(questionOpt)"
                    >
                      <template v-if="questionOpt.isCollapse === 1">
                        <i class="el-icon-caret-bottom" title="展开"></i>
                      </template>
                      <template v-else-if="questionOpt.isCollapse === 0">
                        <i class="el-icon-caret-top" title="收起"></i>
                      </template>
                    </div>
                    <div
                      class="paper-question-action"
                      @click="clickAnswerAnalysisButton(questionOpt)"
                    >
                      答案解析
                    </div>
                  </div>
                </div>
                <div
                  v-if="questionOpt.isCollapseAnswer === 0"
                  class="paper-question-below"
                >
                  <div v-if="questionOpt.newQuestion" class="below-item">
                    <p :key="btnKey" class="below-item-title">
                      <span> 当前试题没有答案解析，已为你推荐同类题 </span>
                      <el-button
                        v-if="planQuestionList.indexOf(questionOpt.newId) == -1"
                        type="text"
                        icon="el-icon-circle-plus-outline"
                        @click="exampaperplanquestion(questionOpt, 1)"
                      >
                        加入试题篮
                      </el-button>
                      <el-button
                        v-else
                        type="text"
                        icon="el-icon-remove-outline"
                        @click="exampaperplanquestionDel(questionOpt, 1)"
                      >
                        移除试题篮
                      </el-button>
                    </p>
                    【同类题】
                    <div v-html="questionOpt.newQuestion"></div>
                  </div>
                  <div
                    v-if="questionOpt.answer && questionOpt.answer.length > 0"
                    class="below-item"
                  >
                    【答案】
                    <div v-html="questionOpt.answer"></div>
                  </div>
                  <div
                    v-if="
                      questionOpt.analysis && questionOpt.analysis.length > 0
                    "
                    class="below-item"
                  >
                    【解答】
                    <div v-html="questionOpt.analysis"></div>
                  </div>
                  <div
                    v-if="questionOpt.analyse && questionOpt.analyse.length > 0"
                    class="below-item"
                  >
                    【分析】
                    <div v-html="questionOpt.analyse"></div>
                  </div>
                  <div
                    v-if="questionOpt.ability && questionOpt.ability.length > 0"
                    class="below-item"
                  >
                    【点评】
                    <div v-html="questionOpt.ability"></div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <!--试卷分析弹窗-->
    <ExamPaperAnalysisForm
      v-if="analysisDialogVisible"
      ref="ExamPaperAnalysisForm"
    ></ExamPaperAnalysisForm>
    <!--下载同类题设置弹窗-->
    <el-dialog
      title="下载设置"
      :visible.sync="dlOptSettingsDialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="550px"
    >
      <el-form label-width="100px">
        <el-form-item label="附带答案">
          <el-checkbox
            v-model="addAnswer"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
        </el-form-item>
        <el-form-item v-if="addAnswer === 1" label="答案位置">
          <el-radio v-model="answerPosition" :label="1">每道试题后面</el-radio>
          <el-radio v-model="answerPosition" :label="2">最后</el-radio>
        </el-form-item>
        <el-form-item label="文档格式">
          <el-radio v-model="fileType" :label="1">Word 文档</el-radio>
          <el-radio v-model="fileType" :label="2">Word 97-2003 文档</el-radio>
          <el-radio v-model="fileType" :label="3">PDF 文档</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="cancelButton"
          @click="dlOptSettingsDialogVisible = false"
          >取 消</el-button
        >
        <el-button class="confirmButton" @click="downloadExamPaperQuestionOpt"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <testBasket
      :key="componentsKey"
      :plan-id="planId"
      @setQuestionList="setQuestionList"
      @setPlanid="setPlanid"
      @getNewList="getNewList"
    ></testBasket>
  </div>
</template>

<script>
import {
  getExamPaperInfo,
  getexampaperzip,
  updateExamPaperCountView,
  downloadExamPaperQuestionOpt,
} from "@/core/api/resource/exampaper";
import testBasket from "@/views/resource/knowledgePointsPaper/components/testBasket.vue";
import {
  exampaperplanquestionDel,
  exampaperplanquestion,
} from "@/core/api/resource/knowledgePointsPaper";
import { answer } from "@/core/api/resource/knowledgePointsPaper";
import {
  getLabel,
  gradeOptions,
  examTypeOptions,
  schoolLevelOptions,
} from "@/core/util/constdata";
import ExamPaperAnalysisForm from "@/views/resource/form/ExamPaperAnalysisForm";
import { continueconditionexampaper } from "@/core/api/resource/breakdownPaper";
import { favObj, unfavObj } from "@/core/api/resource/myResource";

export default {
  name: "ViewPaper",
  components: {
    ExamPaperAnalysisForm,
    testBasket,
  },
  data() {
    return {
      //试题篮 start
      planId: 0,
      componentsKey: 1,
      btnKey: 1,
      planQuestionList: [],
      //试题篮 end
      fullscreenLoading: false,
      getLabel: getLabel,
      gradeOptions: gradeOptions,
      examTypeOptions: examTypeOptions,
      schoolLevelOptions: schoolLevelOptions,
      examPaperId: 0, // 试卷ID
      examPaper: {},
      examPaperType: 0, //0-word 1-pdf 2-img
      examPaperImgs: [],
      hasExamQuestionOpt: 0, // 该试卷是否有变式题
      currentSubjectExamQuestions: [],
      examQuestions: {},
      examPaperQuestions: {},
      displayAllQuestionVar: 0, // 所有原题是否显示变式题
      displayAllOriQuestion: 1, // 是否显示所有原题
      analysisDialogVisible: false, // 试卷分析弹窗
      dlOptSettingsDialogVisible: false, // 下载变式题设置弹窗
      addAnswer: 0, // 下载变式题设置-附带答案 0-不附带 1-附带
      answerPosition: 2, // 下载变式题设置-答案位置 1-每道试题后面 2-最后
      fileType: 1, // 下载文档格式 1-Word 文档 2-Word 97-2003 文档 3-PDF 文档
      CDN: "https://static.wtjy.com/",

      // 试题篮 Demo 数据
      paperBasketData: [
        { type: 1, label: "单选题", count: 2, point: 10 },
        { type: 1, label: "单选题", count: 2, point: 10 },
        { type: 1, label: "单选题", count: 2, point: 10 },
        { type: 1, label: "单选题", count: 2, point: 10 },
      ],
    };
  },
  mounted() {
    window.MathJax.typesetPromise();
  },
  updated() {
    window.MathJax.startup.defaultReady();
    this.$nextTick(() => {
      this.initImg();
    });
  },
  created() {
    if (!this.$route.query.id || parseInt(this.$route.query.id || 0) <= 0) {
      return;
    }
    this.fullscreenLoading = true;
    //this.$store.commit("setIsShowMenu", false);
    this.examPaperId = parseInt(this.$route.query.id || 0);
    getExamPaperInfo(this.examPaperId).then((response) => {
      this.examPaper = response.data.data.examPaper;
      this.getExamPaperType();
      this.examQuestions = response.data.data.examQuestions;
      this.examPaperQuestions = response.data.data.examPaperQuestions;
      this.hasExamQuestionOpt = response.data.data.hasExamQuestionOpt;
      this.refreshSubjectContent();
      this.fullscreenLoading = false;
    });
    updateExamPaperCountView(this.examPaperId);
    this.integratingBasket();
  },
  methods: {
    initImg(type) {
      this.$nextTick(() => {
        var jzjxImg = document.getElementsByClassName("jzjxImg");
        let jzjxImgArr = Array.from(jzjxImg);

        var jzjxAnswerImg = document.getElementsByClassName("jzjxAnswerImg");
        let jzjxAnswerImgArr = Array.from(jzjxAnswerImg);
        jzjxImgArr = jzjxImgArr.concat(jzjxAnswerImgArr);
        if (type) {
          // 答案解析  判断
          jzjxImgArr = jzjxAnswerImgArr;
        }
        let maxWidth = 1170,
          ratio = 1;
        if (jzjxImgArr.length) {
          if (jzjxImgArr[0].style.maxWidth) {
            maxWidth = this.removePxToNumber(jzjxImgArr[0].style.maxWidth);
          }
          let width = this.removePxToNumber(jzjxImgArr[0].style.width);
          if (maxWidth < width) {
            maxWidth = width;
          }
          ratio = 1170 / maxWidth;
        }
        // console.log(jzjxImgArr);
        jzjxImgArr.forEach((item) => {
          const width = this.removePxToNumber(item.style.width);
          if (item.name == "edit") return;
          item.style.width = width * ratio + "px";
          item.style.display = "block";
          item.name = "edit";
        });
      });
    },
    checkFav(item) {
      if (item.relationId) {
        this.delFav(item);
      } else {
        this.addFav(item);
      }
    },
    addFav(item) {
      favObj(item.id).then(() => {
        item.relationId = 1;
        this.$message.success("收藏成功");
      });
    },
    delFav(item) {
      this.$confirm("确认取消收藏当前试卷吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          unfavObj(item.id).then(() => {
            item.relationId = 0;
            this.$message.success("取消收藏成功");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消“取消收藏”操作",
          });
        });
    },
    //试题篮方法 start
    getNewList() {
      // this.getList();
    },
    setPlanid(planId) {
      this.planId = planId;
      // this.getList();
    },
    //已加入试题篮试题列表
    setQuestionList(val) {
      // console.log(val);
      this.planQuestionList = val;
      this.btnKey = new Date().getTime();
    },
    // 移除试题篮
    exampaperplanquestionDel(val, type) {
      let data = {
        examPaperPlanId: this.planId,
        questionId: type ? val.newId : val.id,
      };
      exampaperplanquestionDel(data).then(() => {
        // console.log(res);
        this.$message({
          message: "移除成功！",
          type: "success",
        });
        // 调用 ，更新列表
        // v.isPlan = 0;
        this.componentsKey = new Date().getTime();
      });
    },
    //添加到试题篮
    exampaperplanquestion(val, type) {
      // console.log(val);
      // if (val) {
      //   return;
      // }
      let data = {
        planId: this.planId,
        questionId: type ? val.newId : val.id,
      };

      exampaperplanquestion(data).then(() => {
        // console.log(res);
        this.$message({
          message: "加入成功",
          type: "success",
        });
        // val.isPlan = 1;
        this.componentsKey = new Date().getTime();
      });
    },
    // 试题篮方法 end
    show(v) {
      // console.log(v);
      answer(v.id).then((res) => {
        // console.log(res);
        if (res.data.data.id) {
          v.answer = res.data.data.answer;
          v.analyse = res.data.data.analyse;
          v.analysis = res.data.data.analysis;
          v.ability = res.data.data.ability;
          v.knowledgeNames = res.data.data.knowledgeNames;
          if (res.data.data.id != v.id) {
            v.newQuestion = res.data.data.question;
            // v.knowledgeNames = res.data.data.knowledgeNames;
            v.newId = res.data.data.id;
            v.newXuekeId = res.data.data.xuekeId;
          }
          this.clickAnswerAnalysisButton(v);
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
    },
    refreshSubjectContent() {
      this.currentSubjectExamQuestions = [];
      let checkedExamQuestionMaterialOrders = []; // 已处理的材料题试题的题号
      cycle: for (let i = 0; i < this.examPaperQuestions.length; i++) {
        // 遍历切题数据，获取原题
        let examPaperQuestion = this.examPaperQuestions[i];
        if (
          examPaperQuestion.examQuestionMaterialOrder &&
          examPaperQuestion.examQuestionMaterialOrder.length > 0
        ) {
          // 材料题
          // 只处理第1小题的切题数据
          let found = false;
          for (
            let ci = 0;
            ci < checkedExamQuestionMaterialOrders.length;
            ci++
          ) {
            if (
              checkedExamQuestionMaterialOrders[ci] ===
              examPaperQuestion.examQuestionMaterialOrder
            ) {
              found = true;
              continue cycle;
            }
          }
          if (!found) {
            checkedExamQuestionMaterialOrders.push(
              examPaperQuestion.examQuestionMaterialOrder
            );
          }
        }
        let examQuestion = this.getExamQuestionById(
          examPaperQuestion.examQuestionId
        );
        if (examQuestion) {
          examQuestion.examPaperNum = examPaperQuestion.examQuestionOrder; // 试题题号
          // 有材料题题号则使用材料题题号作为试题题号
          if (
            examPaperQuestion.examQuestionMaterialOrder &&
            examPaperQuestion.examQuestionMaterialOrder.length > 0
          ) {
            examQuestion.examPaperNum =
              examPaperQuestion.examQuestionMaterialOrder;
          }
          examQuestion.knowledgeArray = this.getKnowledgeArrayByNames(
            examQuestion.knowledgeNames
          ); // 设置知识点数组
          examQuestion.display = 1; // 是否显示 原题默认显示
          examQuestion.isCollapse = 0; // 是否收起状态
          examQuestion.isCollapseAnswer = 1; // 是否收起答案
          examQuestion.examPaperQuestionIds = examPaperQuestion.id; // 试题对应的切题表数据ID，用于编辑试题功能
          examQuestion.displayOpt = 0; // 是否显示变式题
          // 获取该试题的变式题
          examQuestion.examQuestionOpts = []; // 变式题数组
          // 查看试卷页面只需要现实第一道变式题即可
          for (let oi = 1; oi <= 1; oi++) {
            if (examPaperQuestion["examQuestionOpt" + oi] > 0) {
              let examQuestionOpt = this.getExamQuestionById(
                examPaperQuestion["examQuestionOpt" + oi]
              );
              if (examQuestionOpt) {
                examQuestionOpt.knowledgeArray = this.getKnowledgeArrayByNames(
                  examQuestionOpt.knowledgeNames
                ); // 设置知识点数组
                examQuestionOpt.display = 0; // 是否显示 材料题默认隐藏
                examQuestionOpt.isCollapse = 0; // 是否收起状态
                examQuestionOpt.isCollapseAnswer = 1; // 是否收起答案
                examQuestionOpt.examPaperQuestionIds = examPaperQuestion.id; // 试题对应的切题表数据ID，用于编辑试题功能
                examQuestionOpt.qOptNum = oi; // 变式题套号
                examQuestion.examQuestionOpts.push(examQuestionOpt);
              }
            }
          }
          this.currentSubjectExamQuestions.push(examQuestion);
        }
      }
      this.displayAllQuestionVar = 0;
    },
    getExamQuestionById(id) {
      for (let i = 0; i < this.examQuestions.length; i++) {
        let examQuestion = this.examQuestions[i];
        if (examQuestion.id === id) {
          return JSON.parse(JSON.stringify(examQuestion));
        }
      }
    },
    getKnowledgeArrayByNames(knowledgeNames) {
      let array = [];
      if (knowledgeNames && knowledgeNames.length > 0) {
        array = knowledgeNames.split("#");
      }
      return array;
    },
    getLabelByValue(value, data) {
      let label = "";
      data.forEach((item) => {
        if (item.value === value) {
          label = item.label;
        }
      });
      return label;
    },
    dataIsEmpty(field) {
      if (typeof field === "undefined" || field === null || field === "") {
        return true;
      } else {
        return false;
      }
    },
    getAddress(province, city) {
      let _province = "";
      let _city = "";
      if (!this.dataIsEmpty(city)) {
        _city = city.split("-")[0];
      }
      if (!this.dataIsEmpty(province)) {
        _province = province.split("-")[0];
      } else {
        _province = "全国";
        _city = "";
      }

      if (_city == _province) {
        _city = "";
      }
      return _province + _city;
    },
    //试卷分析
    paperAnalysis(id) {
      this.analysisDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ExamPaperAnalysisForm.init(id);
      });
    },
    getExamPaperType() {
      let fileUrl = this.examPaper.filePath;
      if (!this.dataIsEmpty(fileUrl)) {
        if (fileUrl.endsWith("docx")) {
          this.examPaperType = 0;
        } else if (fileUrl.endsWith("pdf")) {
          this.examPaperType = 1;
          this.getExamPaperImages();
        } else {
          this.examPaperType = 2;
          this.getExamPaperImages();
        }
      }
    },
    getExamPaperImages() {
      let path = this.examPaper.resultPath;
      if (!path) {
        return;
      }

      this.examPaperImgs = path.split(",");
      for (let i = 0; i < this.examPaperImgs.length; i++) {
        this.examPaperImgs[i] = this.CDN + this.examPaperImgs[i];
      }
    },
    downloadExamPaper() {
      if (!this.examPaper.filePath && !this.examPaper.zipPath) {
        this.$message.error("试卷文件不存在！");
        return;
      }
      // 下载试卷增加试卷的浏览数
      updateExamPaperCountView(this.examPaper.id);
      // if (
      //   this.examPaper.filePath.endsWith(".doc") ||
      //   this.examPaper.filePath.endsWith(".docx") ||
      //   this.examPaper.filePath.endsWith(".pdf")
      // ) {
      //   this.downloadRequest(
      //     this.CDN + this.examPaper.filePath,
      //     this.examPaper.name
      //   );
      // } else {
      this.fullscreenLoading = true;
      getexampaperzip(this.examPaper.id)
        .then((response) => {
          this.downloadRequest(response.data.data.url, response.data.data.name);
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
      // }
    },
    downloadRequest(url, name) {
      let request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "blob";
      request.onload = function () {
        let url = window.URL.createObjectURL(request.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      };
      request.send();
    },
    downloadExamPaperQuestionOpt() {
      this.$message.info("文档生成中，请耐心等待！");
      this.dlOptSettingsDialogVisible = false;
      downloadExamPaperQuestionOpt({
        id: this.examPaper.id,
        addAnswer: this.addAnswer,
        answerPosition: this.answerPosition,
        fileType: this.fileType,
      }).then((response) => {
        if (response.code === 0 || response.data.code === 0) {
          this.downloadRequest(response.data.data.url, response.data.data.name);
        } else {
          this.$message.error(response.msg);
        }
      });
    },
    analyseExamPaper() {
      this.analysisDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ExamPaperAnalysisForm.init(this.examPaperId);
      });
    },
    linkToIndex() {
      let data = {
        id: this.examPaper.id,
      };
      continueconditionexampaper(data).then((res) => {
        // console.log(res);
        this.pageId = res.data.data;
        this.$router.push({
          name: "BreakdownPaper",
          query: {
            id: this.pageId,
          },
        });
        // this.getInitData();
      });
    },
    clickDisplayAllQuestionVar() {
      this.displayAllQuestionVar = 1 - this.displayAllQuestionVar;
      for (let i = 0; i < this.currentSubjectExamQuestions.length; i++) {
        let question = this.currentSubjectExamQuestions[i];
        question.displayOpt = this.displayAllQuestionVar;
        for (let oi = 0; oi < question.examQuestionOpts.length; oi++) {
          let examQuestionOpt = question.examQuestionOpts[oi];
          examQuestionOpt.display = question.displayOpt;
        }
      }
    },
    clickDisplayQuestionVar(question) {
      question.displayOpt = 1 - question.displayOpt;
      for (let i = 0; i < question.examQuestionOpts.length; i++) {
        let examQuestionOpt = question.examQuestionOpts[i];
        examQuestionOpt.display = question.displayOpt;
      }
      if (question.displayOpt === 1) {
        this.displayAllQuestionVar = 1;
      } else if (question.displayOpt === 0) {
        let isDoNotDisplayAll = true;
        for (let i = 0; i < this.currentSubjectExamQuestions.length; i++) {
          if (this.currentSubjectExamQuestions[i].displayOpt === 1) {
            isDoNotDisplayAll = false;
            break;
          }
        }
        if (isDoNotDisplayAll) {
          this.displayAllQuestionVar = 0;
        }
      }
    },
    clickDisplayAllOriQuestion() {
      this.displayAllOriQuestion = 1 - this.displayAllOriQuestion;
      this.displayAllQuestionVar = 1;
      for (let i = 0; i < this.currentSubjectExamQuestions.length; i++) {
        let question = this.currentSubjectExamQuestions[i];
        question.display = this.displayAllOriQuestion;
        question.displayOpt = this.displayAllQuestionVar;
        for (let oi = 0; oi < question.examQuestionOpts.length; oi++) {
          let examQuestionOpt = question.examQuestionOpts[oi];
          examQuestionOpt.display = question.displayOpt;
        }
      }
    },
    displayDifficulty(difficulty) {
      let str = "容易";
      if (difficulty <= 1 && difficulty >= 0.9) {
        str = "容易";
      } else if (difficulty < 0.9 && difficulty >= 0.7) {
        str = "较易";
      } else if (difficulty < 0.7 && difficulty >= 0.5) {
        str = "一般";
      } else if (difficulty < 0.5 && difficulty >= 0.3) {
        str = "较难";
      } else if (difficulty < 0.3 && difficulty >= 0) {
        str = "困难";
      }
      return str;
    },
    displayGradeScoringRate(gradeScoringRate) {
      let rate = gradeScoringRate;
      if (!gradeScoringRate) {
        rate = 0;
      }
      rate = rate * 100;
      rate = rate.toFixed(2);
      return rate + " %";
    },
    clickExamQuestionCollapseIcon(question, isClickContent) {
      if (isClickContent && question.isCollapse === 0) {
        return;
      }
      question.isCollapse = 1 - question.isCollapse;
    },
    clickAnswerAnalysisButton(question) {
      //  answer analysis analyse ability
      // console.log("11111111111111");
      // console.log(question);
      // console.log(!question.analysis);
      if (!question.analysis) {
        this.show(question);
        return;
      }
      // if(question.)
      // console.log(question);
      // console.log(question.isCollapseAnswer);
      question.isCollapseAnswer = 1 - question.isCollapseAnswer;
    },
    //试题篮数据整合
    integratingBasket() {
      let _summary = {
        type: 99,
        label: "合计",
        count: 0,
        point: 0,
      };
      this.paperBasketData.forEach((item) => {
        _summary.count += item.count;
        _summary.point += item.point;
      });
      this.paperBasketData.push(_summary);
    },
    clearCurrentType() {},
    getshowTips(item, file) {
      // console.log(item.filePath)
      let type = false;
      if (item.filePath) {
        if (file == 1) {
          if (item.filePath.endsWith("pdf")) {
            type = true;
          }
        }
        if (file == 3) {
          if (item.filePath.endsWith("doc") || item.filePath.endsWith("docx")) {
            type = true;
          }
        }
        if (file == 2) {
          if (
            !item.filePath.endsWith("doc") &&
            !item.filePath.endsWith("docx") &&
            !item.filePath.endsWith("pdf")
          ) {
            type = true;
          }
        }
      }
      return type;
    },
  },
};
</script>

<style lang="scss">
.view-paper {
  .paper-info {
    .el-button + .el-button {
      margin-left: -4px;
    }
    .el-button {
      padding: 9px !important;
    }
  }
  .basket-popup {
    .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: rgba(16, 198, 148, 0.1) !important;
    }
    .el-table--enable-row-hover .el-table__header tr:hover > th {
      background-color: rgba(16, 198, 148, 0.1) !important;
    }
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: none;
    }
    .el-table th > .cell {
      font-size: 14px;
      font-weight: 400;
      // color: #2474ED;
    }
    .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      background-color: transparent;
    }
  }
}
</style>

<style scoped lang="scss">
.view-paper {
  margin: 16px auto 0 auto;
  padding-bottom: 48px;
  background-color: #ffffff;
  .el-icon-caret-top,
  .el-icon-caret-bottom {
    font-weight: bold;
    font-size: 18px;
  }

  .paper-title {
    border-bottom: 1px solid #edeff0;
    .go-back-cnt {
      width: 1220px;
      padding: 10px 0;
      margin: 0 auto;
      > div {
        cursor: pointer;
      }
    }
  }
  .paper-cnt {
    width: 1220px;
    margin: 0 auto;
  }
  .paper-info {
    // width: 1152px;
    width: 1220px;

    border: 1px solid #e9e9e9;
    padding: 18px;
    margin: 18px auto;
    .label-cnt {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      color: #dddddd;
      padding-top: 8px;
      .text {
        //
        .text-btm {
          display: flex;
          div {
            color: #5e6a7a;
          }
        }
        .normal {
          color: rgba(0, 0, 0, 1);
          font-size: 14px;
          margin-right: 33px;
        }
      }
      .btns {
        // display: flex;
        position: relative;

        .fx-btn {
          position: absolute;
          top: -45px;
          right: 0;
        }
        .el-button + .el-button {
          margin-left: 10px;
        }
        .el-button {
          img {
            vertical-align: bottom;
          }
          .on {
            display: none;
          }
          &:hover {
            img {
              display: none;
            }
            .on {
              display: inline-block;
            }
          }
        }
        .el-button-on {
          color: #2474ed;
          border-color: #2474ed;
          img {
            display: none;
          }
          .on {
            display: inline-block;
          }
        }
      }
    }
    .paper-tips-box {
      display: flex;
      margin: 4px 0;
      div {
        padding: 3px 8px;
        border-radius: 4px;
        border: 1px solid #ff7303;
        color: #ff7303;
        margin-right: 8px;
      }
      .word-tips {
        border: 1px solid #2474ed;
        color: #2474ed;
      }
      .cut-tips {
        border: 1px solid #149335;
        color: #149335;
      }
      // word-tips cut-tips
    }
    .info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .name {
        font-size: 16px;
        font-weight: 700;
        height: 24px;
        line-height: 24px;
      }
      .grade-type {
        span {
          color: #5e6a7a;
          margin-left: 8px;
        }
      }
    }
    .paper-info-label {
      display: flex;
      justify-content: space-between;

      .tip-box {
        display: flex;
      }
      .btns {
        .btn {
          display: inline-block;
          color: #2474ed;
          cursor: pointer;
        }
        .btn + .btn {
          margin-left: 12px;
        }
      }
    }
  }
  .paper-content-main {
    .paper-content-main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f9fafc;
      padding: 10px;
      margin-bottom: 18px;
    }
    .paper-question {
      border: solid 1px #e0e0e0;

      .paper-question-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        padding: 8px 18px;
        background: #f9fafc;
        border-bottom: 1px solid #e9e9e9;
        .paper-question-header-msg {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          text-align: center;
        }

        .paper-question-num {
          // min-width: 24px;
          padding: 0px 8px;
          height: 24px;
          line-height: 24px;
          font-size: 13px;
          margin-right: 10px;
          color: #ffffff;
          background-color: #2474ed;
          border-radius: 4px;
        }
        .paper-question-var-num {
          min-width: 50px;
          height: 24px;
          line-height: 24px;
          font-size: 13px;
          margin-right: 10px;
          color: #ffffff;
          background-color: #0091ea;
          border-radius: 12px;
        }
        .paper-question-type {
          font-size: 14px;
          font-weight: bold;
          margin-right: 10px;
          color: #000000;
        }
        .paper-question-difficulty {
          font-size: 14px;
          color: #5e6a7a;
        }
        .paper-question-gradescoringrate {
          font-size: 14px;
          margin-left: 10px;
          color: #5e6a7a;
        }
      }
      .paper-question-content {
        font-size: 14px;
        margin: 16px 24px;
        overflow-x: auto;
      }
      .paper-question-content.isCollapse {
        max-height: 160px;
        overflow-x: hidden;
        overflow-y: hidden;
      }
      .paper-question-content::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      .paper-question-content::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
      }
      .paper-question-footer {
        display: flex;
        align-items: center;
        text-align: center;
        height: 40px;
        background: #f9fafc;
        border-top: solid 1px #e0e0e0;
        .paper-question-knowledge {
          display: flex;
          align-items: center;
          text-align: center;
          max-width: 900px;
          overflow: hidden;
          white-space: nowrap;
          margin-left: 24px;
          .paper-question-knowledge-title {
            font-size: 14px;
          }
          .paper-question-knowledge-name {
            font-size: 14px;
            padding: 2px 10px;
            margin: 0 4px;
            border-radius: 4px;
            background-color: #e6e6e4;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .paper-question-action-bar {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-left: auto;
          margin-right: 24px;
          font-size: 14px;
          .paper-question-collapse-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 24px;
            height: 24px;
            margin: 0 10px;
            cursor: pointer;
          }
          .paper-question-action {
            color: #2474ed;
            cursor: pointer;
          }
          .paper-question-action-sepaline {
            margin: 0 10px;
            color: #dddddd;
          }
        }
      }
      .paper-question-below {
        .below-item {
          border-bottom: 1px dashed #dddddd;
          padding: 12px;
          .below-item-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    .paper-question + .paper-question {
      margin-top: 18px;
    }
  }
  .basket {
    padding: 16px 13px;
    background-color: #2474ed;
    border-radius: 4px;
    width: 40px;
    position: absolute;
    left: 1152px;
    top: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      .basket-popup {
        display: block;
      }
    }
    .img-icon {
      width: 20px;
      margin-left: -2px;
    }
    .basket-popup {
      width: 400px;
      position: absolute;
      left: -400px;
      top: 0;
      border: 1px solid #2474ed;
      background-color: #ffffff;
      display: none;
      &:hover {
        display: block;
      }
      .preview-paper {
        background-color: #2474ed;
        color: #ffffff;
        padding: 10px 20px;
        border-radius: 4px;
        margin: 10px auto;
        width: 50%;
        text-align: center;
        letter-spacing: 2px;
        font-size: 16px;
      }
    }
  }
}
</style>
